import { ArrivalIcon, DepartureIcon, PinIcon } from 'components/icons'
import { Field, useForm, useFormState } from 'react-final-form'

import React from 'react'
import dynamic from 'next/dynamic';
import moment from "moment";

const DatePickerAdapter = dynamic(async () => (await import('components/atoms/datepicker')).DatePickerAdapter);
const TimePickerAdapter = dynamic(async () => (await import('components/atoms/timepicker')).TimePickerAdapter);
const ReactGroupedSelectAdapter = dynamic(async () => (await import('components/atoms/select')).ReactGroupedSelectAdapter);


const required = (message = 'Required') => value => (value ? undefined : message);

export const AirportsField = React.forwardRef(function AirportsField({ airportsChoices, dark }, ref) {
    const groupByRegion = airportsChoices.reduce((accum, choice) => {
        if (!accum[choice.region]) accum[choice.region] = {
            label: choice.region,
            options: []
        };
        accum[choice.region].options.push(choice);
        return accum;
    }, {});
    const byRegionOptions = Object.values(groupByRegion);

    return (
        <Field name="airport" validate={required('Please pick an airport')} component={ReactGroupedSelectAdapter} options={byRegionOptions} label="Flying from" icon={<PinIcon className="w-7 h-7" />} dark={dark} placeholder="Pick an airport..." ref={ref} />
    );
});

export function AirportsPills({ airportsChoices, currentAirportSlug }) {
    const form = useForm();

    const pills = airportsChoices
        .filter(choice => choice.includeInQuickPick)
        .filter(choice => !currentAirportSlug || choice.value !== currentAirportSlug)
        .slice(0, 6);

    pills.sort((a, b) => {
        if (a.name === b.name) return 0;
        if (a.name < b.name) return -1;
        return 1;
    });

    return (
        <div className="mt-2 gap-2 grid grid-cols-3 sm:flex sm:flex-wrap">
            {pills
                .map(choice => (
                <button key={choice.value} className="flex-1 bg-pink-700 py-1 px-3 rounded text-white shadow whitespace-nowrap" onClick={() => {
                    form.change('airport', choice.value);
                }}>{choice.name}</button>
            ))}
        </div>
    );
}

export function DropOffDateField({ dark }) {
    return (
        <Field name="arrivalDate" validate={required('Please pick a drop off date')} component={DatePickerAdapter} disabledDate={date => date.isBefore(moment())} label="Drop off" icon={<DepartureIcon className="w-7 h-7" />} className="w-1/2" dark={dark} />
    )
}

export function DropOffTimeField({ dark }) {
    return (
        <Field name="arrivalTime" validate={required('Please pick a drop off time')} component={TimePickerAdapter} dark={dark} className="w-1/2" />
    )
}

export function PickUpDateField({ dark }) {
    const { values } = useFormState();

    return (
        <Field name="departureDate" validate={required('Please select a pick up date')} component={DatePickerAdapter} disabledDate={date => date.isBefore(values.arrivalDate)} label="Pick up"  icon={<ArrivalIcon className="h-7 w-7" />} className="w-1/2" dark={dark} />
    )
}

export function PickUpTimeField({ dark }) {
    return (
        <Field name="departureTime" validate={required('Please select a pick up time')} component={TimePickerAdapter} dark={dark} className="w-1/2" />
    )
}
